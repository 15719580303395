import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';
import PropTypes from 'prop-types';

import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

const MoneyMachine = ({ data }) => {
  return (
    <Layout>
      <SEO title="Money Machine® 2 self-service coin counter" />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className="flex flex-wrap w-full pb-8 mx-auto mb-8 border-b-2 border-transparent max-w-7xl">
        <div className="flex items-center justify-center w-full md:w-1/2">
          <div className="w-full max-w-lg px-4 mx-auto">
            <h2 className="text-sm font-bold leading-none tracking-wide uppercase text-brand-blue">
              Money Machine® 2 self-service coin counter
            </h2>
            <h3 className="mb-6 text-3xl font-bold leading-none">
              Faster, highly accurate, easy to use
            </h3>
            <p className="mb-4">
              Money Machine 2 is faster, has greater coin capacity, is highly
              accurate and very easy to use. Best of all, it’s affordably
              priced.
            </p>
            <p className="mb-4">
              Draw new customers and increase the frequency of return visits.
            </p>
            <ul className="mb-4 ml-4 list-disc">
              <li>
                Financial institutions benefit from increased customer traffic,
                enhanced customer loyalty and improved operational efficiency
              </li>
              <li>
                Supermarkets can generate more revenue in a small footprint and
                serve customers better through speed and convenience
              </li>
            </ul>
            <h3 className="mb-6 text-3xl font-bold leading-none">Features</h3>
            <ul className="mb-4 ml-4 list-disc">
              <li>
                <strong>High capacity bins:</strong> Ultra-strong polymer bins
                are easy to change using lightweight dollies. Coins are securely
                collected and stored for transporting a 70,000
                mixed-coin-capacity single bin or in dual bins with a combined
                capacity of up to 140,000 mixed coins
              </li>
              <li>
                <strong>Enhanced coin discrimination:</strong> Greater accuracy
                via enhanced coin discrimination technology to differentiate
                coin more precisely, provides for more accurate count
              </li>
              <li>
                <strong>Quieter coin operation:</strong> Quieter coin
                processing, makes it less distracting for tellers and customer
                service personnel
              </li>
              <li>
                <strong>Advanced debris management:</strong> Debris such as
                paper clips, screws, bolts and bent coins are diverted and
                captured for easy disposal
              </li>
              <li>
                <strong>Continuous suspect coin detection:</strong> Suspect
                coins are not counted but diverted to a coin return cup, all
                while maintaining unmatched counting and sorting speed
              </li>
              <li>
                <strong>Transaction memory and reporting:</strong> As many as
                65,000 coin processing transactions are stored in memory. Daily
                reports can be easily obtained at any time by authorized
                employees
              </li>
              <li>
                <strong>38 cm colour touch-screen display:</strong> An intuitive
                touch-screen display guides your customers through the coin
                redemption process
              </li>
              <li>
                <strong>Customized graphics:</strong> Can be placed on the
                initial customer greeting screen, certain text and thank-you
                messages can also be customised
              </li>
              <li>
                <strong>Customizable transaction receipt:</strong> Your logo or
                message can be printed on the top and bottom of the customer
                receipt
              </li>
              <li>
                <strong>Optional graphics:</strong> Offer a wide array of
                machine and signage choices
              </li>
            </ul>
            <Accordion allowZeroExpanded>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Available options</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <div className="p-4">
                    <p>
                      Single bin collection:{' '}
                      <strong>
                        High-strength polymer bins hold up to 70,000 mixed
                        coins.
                      </strong>
                    </p>
                    <br />
                    <h3>
                      <strong>Options:</strong>
                    </h3>
                    <ul className="mb-4 ml-4 list-disc">
                      <li>
                        <strong>Four ways to add coin counters:</strong> Buy,
                        lease, rent, or let us place a machine free of charge.
                      </li>
                      <li>
                        <strong>Onscreen advertising:</strong> Advertise
                        products and services through fixed images or continuous
                        play videos providing high visibility to important
                        promotions.
                      </li>
                      <li>
                        <strong>Remote management:</strong> Multiple Money
                        Machine 2 installations can be managed from JetLink ™
                        software.
                      </li>
                      <li>
                        <strong>Warning light:</strong> Address issues quickly
                        and ensure better uptime, a red or yellow light can be
                        programmed to conditionally or steadily flash.
                      </li>
                      <li>
                        <strong>Machine graphics:</strong> Outfit your coin
                        counting machine with eye catching graphics. Choose to
                        design your own, use our brightly colored Cummins
                        Allison standard graphics or opt for stainless steel
                        panels to give an elegant finish to your lobby décor.
                      </li>
                    </ul>
                  </div>
                </AccordionItemPanel>
              </AccordionItem>
              <AccordionItem>
                <AccordionItemHeading>
                  <AccordionItemButton>Specs</AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                  <table>
                    <tbody>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Coin Sorting, Accuracy &amp; Denomination
                        </th>
                      </tr>
                      <tr>
                        <td>Coin Sorting Speed</td>
                        <td>Up to 4,100 coins per minute.</td>
                      </tr>
                      <tr>
                        <td>Coins Sorted</td>
                        <td>1c, 2c, 5c, 10c, 20c, 50c, $1, $2</td>
                      </tr>
                      <tr>
                        <td>Coins Sorting Accuracy</td>
                        <td>99.995%</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Input Tray
                        </th>
                      </tr>
                      <tr>
                        <td>Type</td>
                        <td>Gravity flow tray</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Capacity
                        </th>
                      </tr>
                      <tr>
                        <td>Maximum Capacity</td>
                        <td>
                          Single bin unit: up to 70,000 mixed coins
                          <br />
                          <br />
                          Dual bin unit: up to 140,000 mixed coins <br />
                          (combined total of two bins)
                        </td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Dimensions
                        </th>
                      </tr>
                      <tr>
                        <td>Size</td>
                        <td>
                          Single bin: 66.04 cm W x 73.66 cm D x 142.24 cm H
                          <br />
                          <br />
                          Dual bin unit: 66.04 cm W x 100.33 cm D x 142.24 cm H
                        </td>
                      </tr>
                      <tr>
                        <td>Weight</td>
                        <td>Bin Unit 275 lb.</td>
                      </tr>
                      <tr>
                        <th align="left" colSpan={2}>
                          {' '}
                          Power Specs
                        </th>
                      </tr>
                      <tr>
                        <td>Voltage</td>
                        <td>
                          Voltage operating range 105-130VAC. Frequency 50/60Hz.
                        </td>
                      </tr>
                      <tr>
                        <td>Power Consumption</td>
                        <td>9.0 Amps (120v); 4.5 Amps (220v)</td>
                      </tr>
                    </tbody>
                  </table>
                </AccordionItemPanel>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
        <div className="w-full px-4 mt-8 md:pl-8 md:mt-0md:w-1/3">
          <Image fluid={data.MoneyMachine.childImageSharp.fluid} />
        </div>
      </div>
    </Layout>
  );
};

MoneyMachine.propTypes = {
  data: PropTypes.object,
};

export const query = graphql`
  query MoneyMachineQuery {
    MoneyMachine: file(relativePath: { eq: "money-machine.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1764) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default MoneyMachine;
